// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  ヘッダーメニューボタン（SP）
// ==================================================
const menuNavigation = document.querySelector(".header_menuNavigation-js");
const menuNavigationActiveClass = "header_menuNavigation-js-active";
const menuButton = document.querySelector(".header_menuButton-js");
const menuButtonActiveClass = "header_menuButton-js-active";
function menuToggle() {
  menuNavigation.classList.toggle(menuNavigationActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  menuToggle();
});

// ==================================================
//  アンカーのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
// const headerHeight = document.querySelector("header").offsetHeight;
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    // メニューの開閉
    // メニューが開いていたら閉じる
    if (menuNavigation.classList.contains(menuNavigationActiveClass)) {
      menuToggle();
    }
    // // メニューの開閉

    const href = anchors[i].getAttribute("href");
    if (href !== "#top" && href !== "#") {
      const target = document.getElementById(href.replace("#", ""));
      // const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight + 40;
      const position = window.pageYOffset + target.getBoundingClientRect().top;

      window.scroll({
        top: position,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  });
}

// ==================================================
//  スクロールで画面内に入った動画を再生
//  videoタグには muted と playsinline を必ず設定すること。
// ==================================================
window.addEventListener(
  "load",
  function () {
    function playVideos(videoWrappers) {
      const startPosition = window.pageYOffset + window.innerHeight;
      for (let i = 0; i < videoWrappers.length; i++) {
        const videoPosition = videoWrappers[i].getBoundingClientRect().top + window.pageYOffset;
        const video = videoWrappers[i].getElementsByTagName("video");
        if (startPosition > videoPosition) {
          video[0]
            .play()
            .then(() => {
              // console.log("省電力モードではありません");
              video[0].setAttribute("oncontextmenu", "return false;"); // 右クリックでの操作禁止
            })
            .catch((error) => {
              // 『省電力モード』のときの処理 => コントローラー追加
              // console.log(error, "省電力モードです");
              video[0].setAttribute("controls", "controls"); // 再生ボタンを表示
            });
        } else {
          video[0].pause();
        }
      }
    }
    const videoWrappers = document.getElementsByClassName("video-js");
    if (videoWrappers.length) {
      playVideos(videoWrappers);
      window.addEventListener(
        "scroll",
        function () {
          playVideos(videoWrappers);
        },
        false
      );
    }
  },
  false
);
